/* src/global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* Ensure html and body take up full height and prevent overflow */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: auto;   /* Enable vertical scrolling only when necessary */
  }
  
  /* Ensure the #root div (React root) also takes full height */
  #root {
    height: 100%;
  }
  
  /* Optional: You can reset some default margins/paddings that browsers apply */
  * {
    box-sizing: border-box;
  }
  
/* This CSS can be added to your CSS file or styled-components if you're using them */
.spinner {
    border: 4px solid #f3f3f3; /* Light gray background */
    border-top: 4px solid #3498db; /* Blue color for the spinner */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  